import Vue from 'vue'
import App from './App.vue'
import VueGtag, { bootstrap } from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import router from './router'
import store from './store'
import { util } from '@/utils/utils.js'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueEllipseProgress from 'vue-ellipse-progress'
// Internacionalização
import i18n from './i18n'
import materiali18n from './i18n/pt-BR/material'

// Controle de Permissões
import './permission'
// Plugins para uso global
import './plugins'
// Loader CSS para as páginas
import './assets/css/loader.css'

import 'bootstrap'
// import '@/assets/js/bootstrap.bundle.js'
// window.$ = window.jQuery = require('jquery')

// Validations
import VeeValidate, { Validator } from 'vee-validate'
import { validCNPJ, requiredObject } from '@/utils/validator'

// import VueFacebookPixel from 'vue-analytics-facebook-pixel'
// Vue.use(VueFacebookPixel)

// Imput Mask
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
VeeValidate.Validator.extend('validCNPJ', validCNPJ)
// Custom object validatorn
Validator.extend('required_object', requiredObject)

// Custom Events
Vue.directive('clickoutside', {
  inserted: (el, binding, vnode) => {
    // assign event to the element
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el === event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        vnode.context[binding.expression](event)
      }
    }
    // register click and touch events
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },
  unbind: function (el) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  },
  stopProp (event) {
    event.stopPropagation()
  }
})

// Material Design
Vue.use(VueMaterial)

// // Set config
Vue.material.locale = materiali18n

Vue.use(VueEllipseProgress)
// Uso do componente de Internacionalização
Vue.use({
  i18n: (key, value) => i18n.t(key, value)
})

Vue.prototype.$util = util

Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(Hotjar, {
  id: '1481185', // Hotjar Site ID
  isProduction: Vue.config.productionTip
})

if (Vue.config.productionTip === true) {
  Vue.use(VueGtag, {
    config: { id: 'UA-120119317-1' }
  })
  bootstrap().then((gtag) => {
    // all done!
  })
}

// Instância Global para acesso dentro do projeto
global.instanceApp = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.window = window
