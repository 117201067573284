/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Title_Layout'

const BasicRouter = {
  path: '/basic',
  component: Layout,
  children: [
    {
      path: 'tier',
      name: 'TierIndex',
      component: () => import('@/views/Basic/Tier/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier/:id_tier/edit',
      name: 'TierEdit',
      component: () => import('@/views/Basic/Tier/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier/new',
      name: 'TierNew',
      component: () => import('@/views/Basic/Tier/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-locais',
      name: 'TierLocalIndex',
      component: () => import('@/views/Basic/TierLocal/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_local.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-locais/:id_tier/edit',
      name: 'TierLocalEdit',
      component: () => import('@/views/Basic/TierLocal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_local.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_local.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-locais/new',
      name: 'TierLocalNew',
      component: () => import('@/views/Basic/TierLocal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_local.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_local.title'), disabled: true }
        ]
      }
    },
    // Tier Armador
    {
      path: 'tier-armador',
      name: 'TierArmadorIndex',
      component: () => import('@/views/Basic/TierArmador/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.armador_local.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-armador/:id_tier/edit',
      name: 'TierArmadorEdit',
      component: () => import('@/views/Basic/TierArmador/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_armador.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_armador.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-armador/new',
      name: 'TierArmadorNew',
      component: () => import('@/views/Basic/TierArmador/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_armador.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_armador.title'), disabled: true }
        ]
      }
    },
    // Tier Padrao
    {
      path: 'tier-padrao',
      name: 'TierPadraoIndex',
      component: () => import('@/views/Basic/TierPadrao/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_padrao.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-padrao/:id/edit',
      name: 'TierPadraoEdit',
      component: () => import('@/views/Basic/TierPadrao/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_padrao.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_padrao.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-padrao/new',
      name: 'TierPadraoNew',
      component: () => import('@/views/Basic/TierPadrao/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_padrao.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_padrao.title'), disabled: true }
        ]
      }
    },
    {
      path: 'currencyquote/',
      name: 'CurrencyQuotesIndex',
      component: () => import('@/views/Basic/CurrencyQuote/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.currencyquote.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tariff/import',
      name: 'TariffImportIndex',
      component: () => import('@/views/Basic/Tariff/Import/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tariffimport.title'),
        breadcrumbs: []
      }
    },
    {
      path: 'tariff/import/new',
      name: 'TariffImportNew',
      component: () => import('@/views/Basic/Tariff/Import/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tariffimport.title'),
        breadcrumbs: []
      }
    },
    {
      path: 'tariff/import/config',
      name: 'TariffImportConfig',
      component: () => import('@/views/Basic/Tariff/Import/Config'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tariffimport.title'),
        breadcrumbs: []
      }
    },
    {
      path: 'tariff/lcl',
      name: 'LclTariffsIndex',
      component: () => import('@/views/Basic/Tariff/Lcl/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tarifflcl.title'),
        breadcrumbs: []
      }
    },
    // Goal
    {
      path: 'goal/',
      name: 'GoalIndex',
      component: () => import('@/views/Basic/Goal/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'goal/:year/:id_employee/edit',
      name: 'GoalEdit',
      component: () => import('@/views/Basic/Goal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.goal.title'), disabled: false, href: 'GoalIndex' }
        ]
      }
    },
    {
      path: 'goal/new',
      name: 'GoalNew',
      component: () => import('@/views/Basic/Goal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal.title'),
        breadcrumbs: [
          { text: i18n.t('basic.goal.title'), disabled: true }
        ]
      }
    },
    {
      path: 'goal/indicators',
      name: 'GoalIndicators',
      component: () => import('@/views/Basic/Goal/year'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal.titleSettings'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.goal.title'), disabled: false, href: 'GoalIndex' }
        ]
      }
    },
    // GoalBonus
    {
      path: 'goal-bonus',
      name: 'GoalBonusIndex',
      component: () => import('@/views/Basic/GoalBonus/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-bonus.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'goal-bonus/:year/edit',
      name: 'GoalBonusEdit',
      component: () => import('@/views/Basic/GoalBonus/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-bonus.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.goal-bonus.title'), disabled: false, href: 'GoalIndex' }
        ]
      }
    },
    {
      path: 'goal-bonus/new',
      name: 'GoalBonusNew',
      component: () => import('@/views/Basic/GoalBonus/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-bonus.title'),
        breadcrumbs: [
          { text: i18n.t('basic.goal-bonus.title'), disabled: true }
        ]
      }
    },
    // GoalTier
    {
      path: 'goal-tier',
      name: 'GoalTierIndex',
      component: () => import('@/views/Basic/GoalTier/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'goal-tier/:year/:section/edit',
      name: 'GoalTierEdit',
      component: () => import('@/views/Basic/GoalTier/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.goal-tier.title'), disabled: false, href: 'GoalTierIndex' }
        ]
      }
    },
    {
      path: 'goal-tier/new',
      name: 'GoalTierNew',
      component: () => import('@/views/Basic/GoalTier/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.goal-tier.title'),
        breadcrumbs: [
          { text: i18n.t('basic.goal-tier.title'), disabled: true }
        ]
      }
    },
    // mail Group
    {
      path: 'mail-group',
      name: 'MailGroupIndex',
      component: () => import('@/views/Basic/MailGroup/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.mail-group.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'mail-group/new',
      name: 'MailGroupNew',
      component: () => import('@/views/Basic/MailGroup/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.mail-group.title'),
        breadcrumbs: [
          { text: i18n.t('basic.mail-group.title'), disabled: true }
        ]
      }
    },
    {
      path: 'mail-group/:id/edit',
      name: 'MailGroupEdit',
      component: () => import('@/views/Basic/MailGroup/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.mail-group.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.mail-group.title'), disabled: false, href: 'MailGroupIndex' }
        ]
      }
    },
    // Task Campaign Type
    {
      path: 'task-campaign-type',
      name: 'TaskCampaignTypeIndex',
      component: () => import('@/views/Basic/TaskCampaignType/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-type.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'task-campaign-type/new',
      name: 'TaskCampaignTypeNew',
      component: () => import('@/views/Basic/TaskCampaignType/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-type.title'),
        breadcrumbs: [
          { text: i18n.t('basic.task-campaign-type.title'), disabled: true }
        ]
      }
    },
    {
      path: 'task-campaign-type/:id/edit',
      name: 'TaskCampaignTypeEdit',
      component: () => import('@/views/Basic/TaskCampaignType/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-type.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.task-campaign-type.title'), disabled: false, href: 'TaskCampaignTypeIndex' }
        ]
      }
    },
    // Delivery Restriction
    {
      path: 'task-campaign-delivery-restriction',
      name: 'TaskCampaignDeliveryRestrictionIndex',
      component: () => import('@/views/Basic/TaskCampaignDeliveryRestriction/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-delivery-restriction.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'task-campaign-delivery-restriction/new',
      name: 'TaskCampaignDeliveryRestrictionNew',
      component: () => import('@/views/Basic/TaskCampaignDeliveryRestriction/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-delivery-restriction.title'),
        breadcrumbs: [
          { text: i18n.t('basic.task-campaign-delivery-restriction.title'), disabled: true }
        ]
      }
    },
    {
      path: 'task-campaign-delivery-restriction/:id/edit',
      name: 'TaskCampaignDeliveryRestrictionEdit',
      component: () => import('@/views/Basic/TaskCampaignDeliveryRestriction/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.task-campaign-delivery-restriction.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.task-campaign-delivery-restriction.title'), disabled: false, href: 'TaskCampaignDeliveryRestrictionIndex' }
        ]
      }
    },
    // Cupom
    {
      path: 'cupom',
      name: 'CupomIndex',
      component: () => import('@/views/Basic/Cupom/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'cupom/:id/edit',
      name: 'CupomEdit',
      component: () => import('@/views/Basic/Cupom/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.cupom.title'), disabled: false, href: 'CupomIndex' }
        ]
      }
    },
    {
      path: 'cupom/new',
      name: 'CupomNew',
      component: () => import('@/views/Basic/Cupom/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom.title'),
        breadcrumbs: [
          { text: i18n.t('basic.cupom.title'), disabled: true }
        ]
      }
    },
    // Campanha
    {
      path: 'cupom-campanha',
      name: 'CupomCampanhaIndex',
      component: () => import('@/views/Basic/CupomCampanha/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom-campanha.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'cupom-campanha/:id/edit',
      name: 'CupomCampanhaEdit',
      component: () => import('@/views/Basic/CupomCampanha/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom-campanha.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.cupom-campanha.title'), disabled: false, href: 'CupomCampanhaIndex' }
        ]
      }
    },
    {
      path: 'cupom-campanha/new',
      name: 'CupomCampanhaNew',
      component: () => import('@/views/Basic/CupomCampanha/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.cupom-campanha.title'),
        breadcrumbs: [
          { text: i18n.t('basic.cupom-campanha.title'), disabled: false, href: 'CupomCampanhaIndex' }
        ]
      }
    },
    // De Para
    {
      path: 'de-para',
      name: 'DeParaIndex',
      component: () => import('@/views/Basic/DePara/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.dePara.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'de-para/:id/edit',
      name: 'DeParaEdit',
      component: () => import('@/views/Basic/DePara/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.dePara.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.dePara.title'), disabled: false, href: 'DeParaIndex' }
        ]
      }
    },
    {
      path: 'de-para/new',
      name: 'DeParaNew',
      component: () => import('@/views/Basic/DePara/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.dePara.title'),
        breadcrumbs: [
          { text: i18n.t('basic.dePara.title'), disabled: true }
        ]
      }
    },
    // Cotações - Tarifários inativos
    {
      path: 'cotacoes/tarifario-inativo',
      name: 'TarifarioCotacaoIndex',
      component: () => import('@/views/Basic/TarifarioCotacao/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.tarifarioCotacao'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'cotacoes/tarifario-inativo/:id/edit',
      name: 'TarifarioCotacaoEdit',
      component: () => import('@/views/Basic/TarifarioCotacao/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.tarifarioCotacao'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.tarifarioCotacao'), disabled: false, href: 'DeParaIndex' }
        ]
      }
    },
    {
      path: 'cotacoes/tarifario-inativo/new',
      name: 'TarifarioCotacaoNew',
      component: () => import('@/views/Basic/TarifarioCotacao/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.tarifarioCotacao'),
        breadcrumbs: [
          { text: i18n.t('sidebar.tarifarioCotacao'), disabled: true }
        ]
      }
    }

  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default BasicRouter
