<template>
  <!-- START: Footer-->
  <footer class="page-footer text-center">
    <div>{{ new Date().getFullYear() }} &copy; <strong>Royal Cargo do Brasil</strong>. Todos os direitos reservados</div>
    <div class="whatsapp-fixed">
      <a class="desktop" target="_blank" href="https://web.whatsapp.com/send?phone=5547991892196">
        <img src="/static/images/whatsapp-short.png" alt="whatsapp-icon">
      </a>
    </div>
  </footer>
  <!-- END: Footer-->
</template>

<script>
export default {
  name: 'PageFooter',
  data () {
    return {
      dialog: false
    }
  },
  methods: {
  }
}
</script>
