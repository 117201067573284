import swal from 'sweetalert2'
import moment from 'moment'
/* eslint-disable */
export const util = {
  scrollToTop () {
    global.window.scrollTo(0, 0)
  },
  scrollToFirstError () {
    let elements = document.getElementsByClassName("royalc-error-input")

    if (elements && elements.length > 0) {
      elements[0].scrollIntoView()
    }
  },
  scrollToFirst (className) {
    let elements = document.getElementsByClassName(className)

    if (elements && elements.length > 0) {
      elements[0].scrollIntoView()
    }
  },
  getMonthWritten (value, locale, short ) {
    short = short || true


    let month = {
      '0': 'January',
      '1': 'February',
      '2': 'March',
      '3': 'April',
      '4': 'May',
      '5': 'June',
      '6': 'July',
      '7': 'August',
      '8': 'September',
      '9': 'October',
      '10': 'November',
      '11': 'December'
  }

    let monthPTBR = {
      '0': 'Janeiro',
      '1': 'Fevereiro',
      '2': 'Março',
      '3': 'Abril',
      '4': 'Maio',
      '5': 'Junho',
      '6': 'Julho',
      '7': 'Agosto',
      '8': 'Setembro',
      '9': 'Outubro',
      '10': 'Novembro',
      '11': 'Dezembro'
  }

    if(!locale || locale == 'pt-BR'){
      return monthPTBR[value]
    } else {
      return month[value]
    }
  },
  showLoading (strMensagem) {
    strMensagem = strMensagem || 'Aguarde carregando'

    var strProgressHTML = '<div class="progress">' +
        '   <div class="progress-bar progress-bar-primary progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">' +
        '   </div>' +
        '</div>'

    swal({
      title: '',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      html: '<h4>' + strMensagem + '</h4><br />' + strProgressHTML
    })
  },
  hideLoading () {
    swal.close()
  },
  showMessage (idAlert, strMessage, type, callback) {
    var strType = 'Aviso!'
    switch (type) {
      case 'error':
        strType = 'Erro'
        break
      case 'success':
        strType = 'Sucesso!'
        break
      case 'warning':
        strType = 'Atenção'
        break
      case 'info':
        strType = 'Aviso'
        break
      case 'question':
        strType = 'Cuidado'
        break
    }

    setTimeout(function () {
      swal({
        title: strType,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        html: strMessage,
        type: type
      }).then((result) => {
        if (callback !== undefined) {
          if (typeof window[callback] === 'function') window[callback]()
        }
      })
    }, 1)
  },
  paginationTo (formName, urlBase, pageTo) {
    var lastChar = urlBase.charAt(urlBase.length - 1)

    $('#' + formName).attr('action', urlBase + (lastChar !== '/' ? '/' : '') + pageTo).submit()
  },
  formatValueToFloat (num, type) {
    type = type || 'BRL'
    var _replaces = ['BRL', 'USD', 'PERCENT']
    _replaces['BRL'] = [['R$', ''], ['.', ''], [',', '.']]
    _replaces['USD'] = [['$', ''], [',', '']]
    _replaces['PERCENT'] = [['%', ''], ['.', ''], [',', '.']]

    for (var x = 0; x < _replaces[type].length; x++) {
      num = num.replace(_replaces[type][x][0], _replaces[type][x][1])
    }
    num = parseFloat(num.trim())

    if (isNaN(num) === true) {
      return 0
    } else {
      return num
    }
  },
  replaceAll (string, search, replacement) {
    var target = string;
    return target.split(search).join(replacement);
  },
  // Number format functions
  numberFormat (number, decimals, decPoint, thousandsSep) {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''

    var toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec)
      return '' + (Math.round(n * k) / k)
        .toFixed(prec)
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
  },
  showNumber (number, thousandsSep, defaultValue) {
    defaultValue = (defaultValue !== undefined) ? defaultValue : 0
    let decimals = 0
    let decPoint = ','
    thousandsSep = thousandsSep || '.'
    if (number !== parseInt(number)) {
      decimals = 2
    }

    if (number) {
      return this.numberFormat(number, decimals, decPoint, thousandsSep)
    }

    return defaultValue
  },
  formatToPrice (num, type) {
    type = type || 'BRL'
    var _formtas = ['BRL', 'USD', 'PERCENT']
    _formtas['BRL'] = {'prefix': 'R$ ', 'suffix': '', 'decimal': ',', 'thousand': '.'}
    _formtas['USD'] = {'prefix': '$ ', 'suffix': '', 'decimal': '.', 'thousand': ','}
    _formtas['PERCENT'] = {'prefix': '', 'suffix': ' %', 'decimal': '.', 'thousand': ','}

    if (typeof num !== 'string') { num = String(num) }

    var p = parseFloat(num.trim()).toFixed(2).split('.')
    return _formtas[type]['prefix'] + p[0].split('').reverse().reduce(function (acc, num, i, orig) {
      return num + (i && !(i % 3) ? _formtas[type]['thousand'] : '') + acc
    }, '') + _formtas[type]['decimal'] + p[1] + _formtas[type]['suffix']
  },

  onlyNumbers (e) {
    var keyCode = e.keyCode || e.which
    var pattern = /\d/
    // Permite somente Backspace, Delete e as setas direita e esquerda, números do teclado numérico - 96 a 105 - (além dos números)
    var keys = [ 46, 8, 9, 37, 39, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105 ]

    if (!pattern.test(String.fromCharCode(keyCode)) && $.inArray(keyCode, keys) === -1) {
      return false
    }
  },

  getCurrentDate (time) {
    time = time || false
    const toTwoDigits = num => num < 10 ? '0' + num : num;
    let today = new Date();
    let year = today.getFullYear();
    let month = toTwoDigits(today.getMonth() + 1);
    let day = toTwoDigits(today.getDate());

    let hour = ''
    if (time) {
      hour = `T${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
    } else {
      hour = 'T00:00:00'
    }
    return `${year}-${month}-${day}${hour}`;
  },
  formatStringToDate (date, locale) {
    locale = locale || 'pt-BR'

    let _regex = /(\d{2})\/(\d{2})\/(\d{4})/
    if (locale === 'en-US') {
      _regex = /(\d{4})-(\d{2})-(\d{2})/
    }

    let newDate = new Date(`${date}T00:00:00`.replace(_regex, "$3-$2-$1") )

    if (newDate instanceof Date) {
      return newDate
    } else {
      return null
    }
  },

  slugify (text, separator) {
    separator = separator || '-'
    text = text || ''
    text = text.trim();
    text = text.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    return text
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);
  },

  getChildrenErrors (items) {
    let fields = {};
    for (var prop in items) {
      if (items.hasOwnProperty(prop)) {
        if (items[prop].hasOwnProperty('errors')) {
          fields[prop] = items[prop]['errors'][0];
        }
      }
    }

    return fields;
  },

  getFormErrors (errors) {
    let formatedErrors = [];

    formatedErrors = this.getChildrenErrors(errors.children);

    return formatedErrors
  },

  // Formatters
  formatDateTime (value, locale, emptyValue) {
    emptyValue = emptyValue || null

    if (!value) {
      return emptyValue
    }

    locale = locale || 'pt-BR'

    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }

    if (!value) {
      return null
    }

    let date = new Date(value)

    if (locale === 'pt-BR') {
      options.timeZone = 'America/Sao_Paulo'

      return date.toLocaleString('pt-BR', options)
    } else {
      options.timeZone = 'America/New_York'

      return date.toLocaleString('en-US', options)
    }
  },
  formatDate (value, locale, emptyValue) {
    emptyValue = emptyValue || null

    if (!value) {
      return emptyValue
    }

    locale = locale || 'pt-BR'

    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    if (!value) {
      return null
    }

    let date = new Date(value)

    if (locale === 'pt-BR') {
      options.timeZone = 'America/Sao_Paulo'

      return date.toLocaleString('pt-BR', options)
    } else {
      options.timeZone = 'America/New_York'

      return date.toLocaleString('en-US', options)
    }
  },
  formatDateWritten (value, locale, emptyValue) {
    emptyValue = emptyValue || null

    if (!value) {
      return emptyValue
    }

    locale = locale || 'pt-BR'

    let date = new Date(value)

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    if (locale === 'pt-BR') {

      return day + ' ' + this.getMonthWritten(month, locale) + ' ' + year;
    } else {
      return this.getMonthWritten(month, locale) + ' ' + day + ' ' + year;
    }
  },
  formatEmpty (value, defaultValue) {
    if (!value) {
      if(defaultValue) {
        return defaultValue
      } else {
        return ' - '
      }
    }

    return value
  },
  formatUrlEmpty (value, defaultValue) {
    if (!value) {
      if(defaultValue) {
        return defaultValue
      } else {
        return ' - '
      }
    }

    return decodeURI(value)
  },
  formatUser (value) {
    if (!value) {
      return ' - '
    }

    let position = value.indexOf('@')

    if (position <= 0) {
      position = value.length
    }

    return value.substring(0, position)
  },
  formatNumber (number, decimals, decPoint, thousandsSep, defaultValue) {
    defaultValue = (defaultValue !== undefined) ? defaultValue : 0
    decimals = (decimals !== undefined) ? decimals : 2
    decPoint = decPoint || ','
    thousandsSep = thousandsSep || '.'

    if (number !== undefined && number !== null) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    }

    return defaultValue
  },
  formatNumberAbrev (number, decimals, decPoint, thousandsSep, defaultValue) {
    defaultValue = (defaultValue !== undefined) ? defaultValue : 0
    decimals = (decimals !== undefined) ? decimals : 2
    decPoint = decPoint || ','
    thousandsSep = thousandsSep || '.'


    let checkNumber = parseFloat(number.toFixed(decimals))

    if(Number.isInteger(checkNumber)) {
      decimals = 0
    }

    if (number !== undefined && number !== null) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    }

    return defaultValue
  },
  formatBoolean (value, allowEmpty) {
    allowEmpty = allowEmpty || false
    if (!value) {
      if (allowEmpty && value == null ) {
        return ''
      }else {
        return global.instanceApp.$i18n.t('nao')
      }
    }
    return global.instanceApp.$i18n.t('sim')
  },
  formatRegraComissao (value) {
    if (value === null || value === undefined) {
      return ' '
    }

    let statusList = [
      {
        'id': 'mbs_3_por_cento',
        'label': 'MBS 3%',
        'color': '#f1ba26'
      },
      {
        'id': 'comissao_faixa',
        'label': 'Faixa',
        'color': '#5ec65f'
      }
    ]

    let status = statusList.find(obj => {
      if (obj.id === value) {
        return obj
      }
    })

    return '<span style="color: ' + status.color + '">' + status.label + '</span>'
  },
  formatNcm (value, defaultValue) {
    defaultValue = (defaultValue !== undefined) ? defaultValue : '-'
    if (!value) {
      return defaultValue
    }

    let capituloPosicao  = value.substr(0, 4)
    let subposicaoItem = value.substr(4, 2)
    let subitem = value.substr(6)

    return capituloPosicao + '.' + subposicaoItem + '.' + subitem
  },
  getNumberFromFormated (value) {
    if (!value) {
      return null
    }

    let numberValue = value
    if(typeof value != 'number' && numberValue.indexOf(',') !== -1) {
      numberValue = numberValue.replaceAll('.', '')
      numberValue = numberValue.replaceAll(',','.')
    }

    return parseFloat(numberValue)
  },
  tomorrow () {
    let today = moment()
    let tomorrow = moment(today).add(1, 'days')

    return tomorrow.toDate()
  },
  addDays(date, days) {
    let newDate = moment(date).add(days, 'days')
    return newDate.toDate()
  },
  mountLink (itemName, object, idProperty) {
    idProperty = idProperty || 'id'

    return global.instanceApp.$router.resolve({ name: itemName, params: { id: object[idProperty] } }).href
  },
  nl2br (str, is_xhtml) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Philip Peterson
    // +   improved by: Onno Marsman
    // +   improved by: Atli Þór
    // +   bugfixed by: Onno Marsman
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Maximusya
    // *     example 1: nl2br('Kevin\nvan\nZonneveld');
    // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
    // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
    // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },
  years (minYear) {
    minYear = minYear || 2013
    let years = []
    let maxYear = parseInt(new Date().getFullYear()) + 1

    for (let i = minYear; i <= maxYear; i++) {
      years.push(i)
    }

    return years
  },
  months () {
    let months = {
      '1': 'Janeiro',
      '2': 'Fevereiro',
      '3': 'Março',
      '4': 'Abril',
      '5': 'Maio',
      '6': 'Junho',
      '7': 'Julho',
      '8': 'Agosto',
      '9': 'Setembro',
      '10': 'Outubro',
      '11': 'Novembro',
      '12': 'Dezembro'
    }

    return months;
  },
  quarters () {
    let quarters = {
      '1': '1º',
      '2': '2º',
      '3': '3º',
      '4': '4º'
    }

    return quarters;
  },
  formatModalidadeProcesso (value) {
    if (!value) {
      return ' - '
    }

    value = parseInt(value)

    let typeList = [
      {
        'id': 1,
        'label': global.instanceApp.$i18n.t('modalidadeProcessoList[1]'),
        'color': '#5ec65f'
      },
      {
        'id': 2,
        'label': global.instanceApp.$i18n.t('modalidadeProcessoList[2]'),
        'color': '#ababab'
      },
      {
        'id': 2,
        'label': global.instanceApp.$i18n.t('modalidadeProcessoList[3]'),
        'color': '#ababab'
      }
    ]

    let status = typeList.find(obj => {
      if (obj.id === value) {
        return obj
      }
    })

    return status.label
  },
  formatTipoOperacao (value) {
    if (!value) {
      return ' - '
    }

    value = parseInt(value)

    let typeList = [
      {
        'id': 1,
        'label': global.instanceApp.$i18n.t('tipoOperacaoList[1]'),
        'color': '#5ec65f'
      },
      {
        'id': 2,
        'label': global.instanceApp.$i18n.t('tipoOperacaoList[2]'),
        'color': '#ababab'
      },
      {
        'id': 3,
        'label': global.instanceApp.$i18n.t('tipoOperacaoList[3]'),
        'color': '#ababab'
      }
    ]

    let status = typeList.find(obj => {
      if (obj.id === value) {
        return obj
      }
    })

    return status.label
  },
  isset (value) {
    return value !== null && value !== undefined && value !== ''
  },
  filterYearsOptions () {
    let today = new Date()
    let year = today.getFullYear();

    let yearList = [];

    for (let currentYear = 2020; currentYear <= year; currentYear++ ) {
      yearList.push(currentYear)
    }

    return yearList
  }
}
