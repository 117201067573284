import Layout from '@/views/_shared/_Layout'

const DirectorsRouter = {
  path: '/contact/directors/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'ContactDirectors',
      component: () => import('@/views/Contact/Directors'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Fale com os diretores'
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default DirectorsRouter
