/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const SchedulesRouter = {
  path: '/schedules',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'SchedulesIndex',
      component: () => import('@/views/Schedules/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.tariffs'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    },
    {
      path: ':id_schedule/edit',
      name: 'ScheduleEdit',
      component: () => import('@/views/Schedules/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    },
    {
      path: ':id_schedule/log',
      name: 'ScheduleLog',
      component: () => import('@/views/Schedules/TaskLog'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          { text: i18n.t('schedules.tariffs'), disabled: false, href: 'SchedulesIndex' },
          { text: i18n.t('actions.log'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'ScheduleNew',
      component: () => import('@/views/Schedules/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    },
    {
      path: 'settings',
      name: 'TierSettings',
      component: () => import('@/views/Schedules/TierSettings/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.tier_settings'),
        breadcrumbs: [
          { text: i18n.t('sidebar.tier_settings'), disabled: true }
        ]
      }
    },
    {
      path: 'settings/:id/edit',
      name: 'TierSettingsEdit',
      component: () => import('@/views/Schedules/TierSettings/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.tier_settings'),
        breadcrumbs: [
          { text: i18n.t('sidebar.tier_settings'), disabled: true }
        ]
      }
    },
    {
      path: '/task-campaign',
      name: 'SchedulesTaskCampaingIndex',
      component: () => import('@/views/Schedules/TaskCampaign/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.taskCampaign'),
        breadcrumbs: [
        ]
      }
    },
    {
      path: '/task-campaign/new',
      name: 'SchedulesTaskCampaingNew',
      component: () => import('@/views/Schedules/TaskCampaign/form'),
      hidden: true,
      meta: {
        onLeaveConfirm: true,
        titleHeader: true,
        titleText: i18n.t('sidebar.taskCampaign'),
        breadcrumbs: [
          { text: i18n.t('sidebar.taskCampaign'), disabled: false, href: 'SchedulesTaskCampaingIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: '/task-campaign/:id_schedule/edit',
      name: 'SchedulesTaskCampaingEdit',
      component: () => import('@/views/Schedules/TaskCampaign/form'),
      hidden: true,
      meta: {
        onLeaveConfirm: true,
        titleHeader: true,
        titleText: i18n.t('sidebar.taskCampaign'),
        breadcrumbs: [
          { text: i18n.t('sidebar.taskCampaign'), disabled: false, href: 'SchedulesTaskCampaingIndex' },
          { text: i18n.t('actions.edit'), disabled: true }
        ]
      }
    },
    {
      path: '/task-campaign/:id_schedule/log',
      name: 'ScheduleTaskCampaignLog',
      component: () => import('@/views/Schedules/TaskCampaign/TaskLog'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.taskCampaign'),
        breadcrumbs: [
          { text: i18n.t('sidebar.taskCampaign'), disabled: false, href: 'SchedulesTaskCampaingIndex' },
          { text: i18n.t('actions.log'), disabled: true }
        ]
      }
    },
    {
      path: '/agent-alert',
      name: 'SchedulesAgentAlertIndex',
      component: () => import('@/views/Schedules/TaskCampaign/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.taskCampaign'),
        breadcrumbs: [
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default SchedulesRouter
