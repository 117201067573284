<template>
  <!-- BEGIN: Sidebar-->
  <div>
    <div class="page-sidebar custom-scroll" id="sidebar">
      <!-- <div class="sidebar-header">
        <a class="sidebar-brand" href="/"> <div class="eroyalless">e</div><div class="eroyal">Royal</div> </a>
        <a class="sidebar-brand-mini" href="/">
          <i class="rc-logo font-40"></i>
        </a>
        <span class="sidebar-points">
          <a href="/">
            <i class="rc-logo font-40"></i>
          </a>
        </span>
      </div> -->
      <div class="sidebar-header">
        <a class="sidebar-brand" href="/"> <img class="logoEroyal" src="./eroyal_fundo_escuro.png"/></a>
        <a class="sidebar-brand-mini" href="/">
          <img style="width: 120%; height: 100px;" src="./icone eroyal_fundo escuro.png"/>
        </a>
        <span class="sidebar-points">
          <a href="/">
          </a>
        </span>
      </div>
      <ul class="sidebar-menu metismenu">
        <li class="heading"><span>DASHBOARDS</span></li>
          <!-- ?????????? -->
            <li v-if="isUserAllowOnlineInvoice"></li>
            <!-- Cotações Online -->
            <li v-if="isSuperAdmin || isPortalTester || isUserAllowOnlineProposal">
              <router-link :to="{name: 'ProposalIndex'}">
                <i class="sidebar-item-icon ft-clock"></i><span class="nav-label">{{ $t('sidebar.proposal') }}</span>
              </router-link>
            </li>
            <!-- Processos -->
            <li>
              <router-link :to="{name: 'ProcessList'}">
                <i class="sidebar-item-icon ft-archive"></i><span class="nav-label">{{ $t('kpi.process.title') }}</span>
              </router-link>
            </li>
            <!-- KPI -->
            <li v-show="user">
              <a><i class="sidebar-item-icon rc-Ativo-56"></i><span class="nav-label">{{ $t('kpi.title') }}</span><i class="arrow la la-angle-right"></i></a>
              <ul class="nav-2-level mm-collapse">
                <!-- 2-nd level-->
                <li>
                  <router-link :to="{name: 'KpiProcesses'}">
                    {{ $t('sidebar.process') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'KpiVolumes'}">
                    {{ $t('sidebar.volumes') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'KpiOriginDestination'}">
                    {{ $t('sidebar.origins/destinations') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'KpiConsigneeShipper'}">
                    {{ $t('sidebar.importers/exporters') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'KpiMajorCarriers'}">
                    {{ $t('sidebar.carriers') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'FinantialValueFreight'}">
                    {{ $t('sidebar.shipping_values') }}
                  </router-link>
                </li>
              </ul>
            </li>
          <!-- <li>
            <router-link :to="{name: 'KpiProcesses'}">
              <i class="sidebar-item-icon rc-Ativo-56"></i><span class="nav-label">{{ $t('kpi.title') }}</span>
            </router-link>
          </li> -->
          <!-- Financeiro -->
          <li>
            <router-link :to="{name: 'FinantialInvoices'}">
            <i class="sidebar-item-icon rc-Ativo-38"></i><span class="nav-label">{{ $t('kpi.finantial_position.title') }}</span>
            </router-link>
          </li>
          <!-- Fale com os Diretores -->
          <li>
            <router-link :to="{name: 'ContactDirectors'}">
              <i class="sidebar-item-icon ti-id-badge"></i><span class="nav-label">{{ $t('contact.directors.title') }}</span>
            </router-link>
          </li>

          <li v-if="isSuperAdmin"  class="heading"><span>INTERNO</span></li>

          <!-- Avaliações -->
          <li v-show="isEmployee || isSuperAdmin">
            <a><i class="sidebar-item-icon ti-comment-alt"></i><span class="nav-label">{{ $t('valuation.aside_menu.title') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li v-if="isEmployee">
                <router-link :to="{name: 'ValuationNew'}">
                  {{ $t('valuation.aside_menu.new') }}
                </router-link>
              </li>
              <li v-if="isSuperAdmin || isDirector || isValuationManager">
                <router-link :to="{name: 'ValuationModerate'}">
                  {{ $t('valuation.aside_menu.moderate') }}
                </router-link>
              </li>
              <li v-if="isSuperAdmin || isDirector || isValuationManager">
                <router-link :to="{name: 'ValuationConfig'}">
                  {{ $t('valuation.aside_menu.config') }}
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Agendamentos -->
          <li v-show="isEmployee || isSuperAdmin">
            <a><i class="sidebar-item-icon ft-calendar"></i><span class="nav-label">{{ $t('sidebar.tasks') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li v-if="isSuperAdmin || isDirector">
                <router-link :to="{name: 'SchedulesTaskCampaingIndex'}">
                  {{ $t('sidebar.taskCampaign') }}
                </router-link>
              </li>
              <li v-if="isEmployee">
                <router-link :to="{name: 'SchedulesIndex'}">
                  {{ $t('sidebar.tariffs') }}
                </router-link>
              </li>
              <li v-if="isSuperAdmin">
                <router-link :to="{name: 'MailAutomaticIndex'}">
                  {{ $t('sidebar.mail-automatic') }}
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Cadastros -->
          <li v-show="isSuperAdmin || isEmployee || isDirector">
            <a>
              <i class="sidebar-item-icon ft-sliders"></i><span class="nav-label">{{ $t('sidebar.settings') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <!-- Cotações -->
              <li  v-show="isEmployee || isSuperAdmin"><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.proposal') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'CupomCampanhaIndex'}">
                      {{ $t('sidebar.cupom-campanha') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'CupomIndex'}">
                      {{ $t('sidebar.cupom') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'DeParaIndex'}">
                      {{ $t('sidebar.dePara') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'TarifarioCotacaoIndex'}">
                      {{ $t('sidebar.tarifarioCotacao') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Disparador -->
              <li  v-show="isSuperAdmin || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.taskCampaign') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'MailGroupIndex'}">
                      {{ $t('basic.mail-group.title') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'MailTemplate'}">
                      {{ $t('sidebar.mail-template') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'TaskCampaignTypeIndex'}">
                      {{ $t('basic.task-campaign-type.title') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin || isEmployee">
                    <router-link :to="{name: 'TaskCampaignDeliveryRestrictionIndex'}">
                      {{ $t('basic.task-campaign-delivery-restriction.title') }}
                    </router-link>
                  </li>
                  <!-- <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'LclTariffsIndex'}">
                      {{ $t('sidebar.lcl_tariffs') }}
                    </router-link>
                  </li> -->
                </ul>
              </li>

              <!-- Importação de tarifários -->
              <li>
                <router-link :to="{name: 'TariffImportIndex'}">
                  {{ $t('sidebar.tariffImport') }}
                </router-link>
              </li>

              <!-- Index -->
              <li  v-show="isSuperAdmin"><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.index') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'IndexDeParaIndex'}">
                      {{ $t('index.de-para.title') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'IndexImportIndex'}">
                      {{ $t('index.import.title') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'IndexTierIndex'}">
                      {{ $t('index.tier.title') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Informativos -->
              <li  v-show="isSuperAdmin"><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.announcement') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'AnnouncementIndex'}">
                      {{ $t('sidebar.announcement') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Metas -->
              <li v-show="isDirector || isSuperAdmin" ><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.goals') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'GoalIndex'}">
                      {{ $t('sidebar.goals') }}
                    </router-link>
                  </li>
                  <li v-if="isDirector || isSuperAdmin">
                    <router-link :to="{name: 'GoalBonusIndex'}">
                      {{ $t('sidebar.goal-bonus') }}
                    </router-link>
                  </li>
                  <li v-if="isDirector || isSuperAdmin">
                    <router-link :to="{name: 'GoalTierIndex'}">
                      {{ $t('sidebar.goal-tier') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Moedas -->
              <li>
                <router-link :to="{name: 'CurrencyQuotesIndex'}">
                  {{ $t('sidebar.currencyquote') }}
                </router-link>
              </li>

              <!-- Tarifários -->
              <li v-show="isSuperAdmin || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('sidebar.tariffs') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'TierSettings'}">
                      {{ $t('sidebar.tier_settings') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'TierArmadorIndex'}">
                      {{ $t('sidebar.tier_armador') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'TierPadraoIndex'}">
                      {{ $t('sidebar.tier_padrao') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'TierIndex'}">
                      {{ $t('sidebar.tier') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'TierLocalIndex'}">
                      {{ $t('sidebar.tier_local') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin || isPricing">
                    <router-link :to="{name: 'TariffLclIndex'}">
                      {{ $t('sidebar.tarifario_lcl') }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- E-mail -->
          <li v-show="isSuperAdmin">
            <a>
              <i class="sidebar-item-icon ft-map"></i><span class="nav-label">{{ $t('sidebar.mail') }}</span>
              <i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li v-if="isSuperAdmin">
                <router-link :to="{name: 'MailIndex'}">
                  {{ $t('sidebar.mail-status') }}
                </router-link>
              </li>
              <!-- 2-nd level-->
              <li v-if="isSuperAdmin">
                <router-link :to="{name: 'MailV2Index'}">
                  {{ $t('sidebar.mail-status') }} Novo
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Empresas -->
          <li>
            <router-link :to="{name: 'CompanyList'}">
              <i class="sidebar-item-icon ti-id-badge"></i><span class="nav-label">{{ $t('company.title') }}</span>
            </router-link>
          </li>

          <!-- Indices -->
          <li v-show="isSuperAdmin || isPricing ">
            <router-link :to="{name: 'IndexList'}">
              <i class="sidebar-item-icon ft ft-trending-up"></i><span class="nav-label">{{ $t('sidebar.index') }}</span>
            </router-link>
          </li>

          <!-- Ferramentas -->
          <li v-show="isEmployee || isSuperAdmin || isFinancialOperating">
            <a>
              <i class="sidebar-item-icon fa fa-tools"></i><span class="nav-label">{{ $t('sidebar.tools') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <!-- NF-s -->
              <li v-show="isSuperAdmin || isFinancialOperating"><a href="javascript:;"><span class="nav-label">{{ $t('invoice.titleNfs') }}</span><i class="arrow la la-angle-right"></i></a>
                <!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin || isFinancialOperating">
                    <router-link :to="{name: 'ToolsNfsInternacional'}">
                      {{ $t('invoice.titleNfsInternacional') }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Integrações -->
          <li v-show="isSuperAdmin || isEmployee || isDirector">
            <a><i class="sidebar-item-icon fa fa-puzzle-piece"></i><span class="nav-label">{{ $t('sidebar.integrations') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <!-- Maersk -->
              <li v-show="isSuperAdmin || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('Maersk') }}</span><i class="arrow la la-angle-right"></i></a>
                <!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'MaerskTaxesIndex'}">
                      {{ $t('integrations.taxes') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'MaerskOriginsDestinationsIndex'}">
                      {{ $t('integrations.origin_destination') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'MaerskTierArmadorIndex'}">
                      {{ $t('integrations.tier_armador') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Webhooks -->
              <li v-show="isSuperAdmin || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('Webhooks') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'WebhookIndex'}">
                      {{ $t('integrations.webhook') }}
                    </router-link>
                  </li>
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'WebhookDeParaIndex'}">
                      {{ $t('integrations.dePara') }}
                    </router-link>
                  </li>
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'WebhookLogsIndex'}">
                      {{ $t('integrations.log') }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Booking -->
          <li v-show="isSuperAdmin || isBookingAdmin || isBookingRequester">
            <a><i class="sidebar-item-icon ft-anchor"></i><span class="nav-label">{{ $t('sidebar.bookings') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li>
                <router-link :to="{name: 'BookingIndex'}">
                  Por Equipamento
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'BookingContractIndex'}">
                  Por Contrato
                </router-link>
              </li>
              <li v-if="isSuperAdmin || isBookingAdmin">
                <router-link :to="{name: 'BookingConfig'}">
                  Configurações
                </router-link>
              </li>
            </ul>
          </li>

          <!-- NF-s -->
          <li v-show="isSuperAdmin || isDirector || isFinancial || isFinancialOperating">
            <a>
              <i class="sidebar-item-icon ft-sliders"></i><span class="nav-label">{{ $t('invoice.titleNfs') }}</span><i class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li v-if="isEmployee">
                <router-link :to="{name: 'InvoiceHome'}">
                  {{ $t('invoice.titleNfsNacional') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'InvoiceInternationalHome'}">
                  {{ $t('invoice.titleNfsInternacional') }}
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Recibos -->
          <li v-show="isSuperAdmin || isDirector || isFinancial || isFinancialOperating">
            <a>
              <i class="sidebar-item-icon ft-sliders"></i><span class="nav-label">{{ $t('sidebar.receipt') }}</span><i class="arrow la la-angle-right"></i>
            </a>
            <ul class="nav-2-level mm-collapse">
              <!-- 2-nd level-->
              <li v-if="isEmployee">
                <router-link :to="{ name: 'ReceiptGenerateIndex' }">
                  {{ $t('invoice.receipt.generate-receipt-title') }}
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Oportunidades -->
          <li v-show="isEmployee">
            <router-link :to="{name: 'OportunidadesIndex'}">
              <i class="sidebar-item-icon ti-target"></i><span class="nav-label">{{ $t('oportunidades.title') }}</span>
            </router-link>
          </li>

          <!-- Relatórios -->
          <li v-show="isSeller || isSuperAdmin || isDirector || isFinancial || isComercialAdmin || isLeader || isEmployee ">
            <a><i class="sidebar-item-icon ft-briefcase"></i><span class="nav-label">{{ $t('report.title') }}</span><i
            class="arrow la la-angle-right"></i></a>
            <ul class="nav-2-level">
              <!-- 2-nd level-->
              <!-- Alertas -->
              <li v-show="isSuperAdmin || isDirector  || isComercialAdmin || isLeader || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('report.alerts.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <!-- Comerciais -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsProcessosFreeTimeCompraMenorVenda'}">
                      {{ $t('report.alerts.comerciais.title') }}
                    </router-link>
                  </li>
                  <!-- Contratos -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsContratosProcessosXContratos'}">
                      {{ $t('report.alerts.contratos.title') }}
                    </router-link>
                  </li>
                  <!-- Controle de Dead Lines -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsProcessosAtrasoRecebimentoDraft'}">
                      {{ $t('report.alerts.controle_dead_lines.title') }}
                    </router-link>
                  </li>
                  <!-- Controle de Processos -->
                  <li v-if="isSuperAdmin || isDirector  || isComercialAdmin || isLeader || isEmployee">
                    <router-link :to="{name: 'ReportAlertsProcessControlProcessesWithoutDispatch'}">
                      {{ $t('report.alertsProcessControl.title') }}
                    </router-link>
                  </li>
                  <!-- Financeiros -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsProcessosDemurrage'}">
                      {{ $t('report.alerts.financeiros.title') }}
                    </router-link>
                  </li>
                  <!-- Monitoria -->
                  <li v-if="isSuperAdmin || isDirector  || isComercialAdmin || isLeader || isEmployee">
                    <router-link :to="{name: 'ReportAlertsMonitoringProcessesWithoutAnswerables'}">
                      {{ $t('report.alertsMonitoring.title') }}
                    </router-link>
                  </li>
                  <!-- Operacionais -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsProcessosPendenciaSeguro'}">
                      {{ $t('report.alerts.operacionais.title') }}
                    </router-link>
                  </li>
                  <!-- Pricing -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportAlertsPricingSolicitacoesPendentes'}">
                      {{ $t('report.alerts.pricing.title') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- 2-nd level-->
              <!-- Auditoria -->
              <li v-show="isSuperAdmin || isFinancial"><a href="javascript:;"><span class="nav-label">{{ $t('report.audit.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <li v-if="isSuperAdmin || isFinancial">
                    <router-link :to="{name: 'AuditProcesses'}">
                      {{ $t('report.audit.processos.title') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- 2-nd level-->
              <!-- Bonificação -->
              <li v-show="isSuperAdmin || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('report.bonificacao.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <!-- Acompanhamento de Bonificação -->
                  <li v-if="isSuperAdmin || isEmployee">
                    <router-link :to="{name: 'ReportBonusStatus'}">
                      {{ $t('report.bonus-status.title') }}
                    </router-link>
                  </li>
                  <!-- Acompanhamento de Metas -->
                  <li v-if="(isEmployee)">
                    <router-link :to="{name: 'ReportSeller2022'}">
                      {{ $t('report.seller.title') }} 2022
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- 2-nd level-->
              <!-- eRoyal -->
              <li v-show="isSuperAdmin || isPricing || isDirector || isComercialAdmin || proposalAdmin || isSeller || isFinancial || isAllowCotacoesOnlineReport "><a href="javascript:;"><span class="nav-label">{{ $t('report.eroyal.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <!-- Acessos do portal -->
                  <li v-if="isSuperAdmin">
                    <router-link :to="{name: 'ReportPortalAccess'}">
                      {{ $t('report.eroyal.portalAccess.title') }}
                    </router-link>
                  </li>
                  <!-- Cotações Online - Indicadores -->
                  <li v-if="isSuperAdmin || isPricing || isDirector  || isComercialAdmin || proposalAdmin || isAllowCotacoesOnlineReport">
                    <router-link :to="{name: 'ReportProposal'}">
                      {{ $t('report.proposal.title') }}
                    </router-link>
                  </li>
                  <!-- Utilização do envio de tarifários -->
                  <li v-if="isSuperAdmin || isDirector || isFinancial || isComercialAdmin ">
                    <router-link :to="{name: 'ReportTariffScheduleUsageSent'}">
                      {{ $t('report.tariff_indicators.title') }}
                    </router-link>
                  </li>
                  <!-- Utilização do Sistema -->
                  <li v-if="isSuperAdmin || isDirector || isFinancial || isComercialAdmin || isSeller ">
                    <router-link :to="{name: 'ReportSystemUsage'}">
                      {{ $t('report.report_system.title') }}
                    </router-link>
                  </li>

                </ul>
              </li>

              <!-- 2-nd level-->
              <!-- Indicadores -->
              <li v-show="isSuperAdmin || isDirector || isOperationalAnalyst || isComercialAdmin || isFinancial || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('report.indicadores.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <!-- Analistas -->
                  <li v-show="isSuperAdmin || isOperationalAnalyst">
                    <router-link :to="{name: 'analyst'}">
                      {{ $t('kpi.analyst.title') }}
                    </router-link>
                  </li>

                  <!-- Extrato Comercial -->
                  <li v-if="isSuperAdmin || isDirector  || isComercialAdmin">
                    <router-link :to="{name: 'ReportStatementCommercialWeekly'}">
                      {{ $t('report.statement.commercial.title') }}
                    </router-link>
                  </li>

                  <!-- Extrato Financeiro -->
                  <li v-if="isSuperAdmin || isDirector || isFinancial ">
                    <router-link :to="{name: 'ReportStatementFinancialWeekly'}">
                      {{ $t('report.statement.financial.title') }}
                    </router-link>
                  </li>

                  <!-- Indicadores -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportIndicatorsCommercial'}">
                      {{ $t('report.indicators.title') }}
                    </router-link>
                  </li>

                  <!-- Indicadores Internos -->
                  <li v-if="isSuperAdmin || isDirector || isFinancial || isComercialAdmin">
                    <router-link :to="{name: 'ReportInternal'}">
                      {{ $t('report.internal.title') }}
                    </router-link>
                  </li>

                  <!-- Relatório Semanal Fechamento RO -->
                  <li v-if="isSuperAdmin || isDirector || isFinancial || isComercialAdmin ">
                    <router-link :to="{name: 'ReportSellerProcessOpened'}">
                      {{ $t('report.report_comercial.opened processes RO') }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Insight Vendas -->
              <li v-if="isSuperAdmin || isDirector || isFinancial || isComercialAdmin || isSeller ">
                <router-link :to="{name: 'ReportSellerInsight'}">
                  {{ $t('report.report_comercial.insight') }}
                </router-link>
              </li>

              <!-- 2-nd level-->
              <!-- Recompra -->
              <li v-show="isSuperAdmin || isDirector  || isEmployee"><a href="javascript:;"><span class="nav-label">{{ $t('report.recompra.title') }}</span><i class="arrow la la-angle-right"></i></a><!-- 3-rd level-->
                <ul class="nav-3-level">
                  <!-- Recompra HC -->
                  <li v-if="isEmployee">
                    <router-link :to="{name: 'ReportRecompraHC'}">
                      {{ $t('report.recompra.recompra_hc') }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Usuários -->
          <li v-show="isSuperAdmin || isFinancial || isSeller || isERoyalSeller ||followsAccessRequests">
            <a>
              <i class="sidebar-item-icon ft-users"></i><span class="nav-label">{{ $t('users.title') }}</span>
              <i class="arrow la la-angle-right"></i></a>
              <ul class="nav-2-level mm-collapse">
                <!-- 2-nd level-->
                <li v-if="isSuperAdmin || isFinancial || isSeller || isERoyalSeller  || followsAccessRequests">
                  <router-link :to="{name: 'RequestAccessIndex'}">
                    {{ $t('form.request-access.title') }}
                  </router-link>
                </li>
              </ul>
          </li>
          <!-- <a href="/" style="position: absolute; bottom: 0;">
            <span class="nav-label">
              <img src="/static/images/login/powered.svg" style="width: 60%; padding-top: 35px; margin-left: 15%" alt="logo" class="logo">
            </span>
          </a> -->
      </ul>
    </div>
    <div class="sidebar-mobile">
        <ul class="menu-mobile">
          <li v-if="isSuperAdmin || isPortalTester || isUserAllowOnlineProposal">
            <router-link :to="{name: 'ProposalIndex'}">
              <i class="sidebar-item-icon-mobile ft-clock"></i><label class="nav-label-mobile">{{ $t('sidebar.proposal') }}</label>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'ProcessList'}">
              <i class="sidebar-item-icon-mobile ft-archive"></i><label class="nav-label-mobile">{{ $t('kpi.process.title') }}</label>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'KpiProcesses'}">
              <i class="sidebar-item-icon-mobile rc-Ativo-56"></i><label class="nav-label-mobile">{{ $t('kpi.title') }}</label>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'FinantialInvoices'}">
              <i class="sidebar-item-icon-mobile rc-Ativo-38"></i><label class="nav-label-mobile">{{ $t('kpi.finantial_position.title') }}</label>
            </router-link>
          </li>
        </ul>
      </div>
  </div>
  <!-- END: Sidebar-->
</template>

<script>
import jQuery from 'jquery'
// eslint-disable-next-line
import MetisMenu from 'metismenu'

// Scroll
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

export default {
  name: 'AsideMenu',
  data () {
    return {
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isUserAllowOnlineInvoice () {
      let userInfo = this.$store.getters.userInfo || null

      if (!userInfo) {
        return false
      } else if (userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      } else if (userInfo.customers_allow_online_invoice_list.length <= 0) {
        return false
      } else if (userInfo.customers_allow_online_invoice_list.length > 0) {
        return true
      }

      return false
    },
    isUserAllowOnlineProposal () {
      let userInfo = this.$store.getters.userInfo || null

      if (!userInfo) {
        return false
      } else if (userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      } else if (userInfo.customers_allow_online_proposal_list.length > 0) {
        return true
      }

      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isAllowCotacoesOnlineReport () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_ALLOW_COTACOES_ONLINE_REPORT') !== -1) {
        return true
      }
      return false
    },
    isOperationalAnalyst () {
      let userInfo = this.$store.getters.userInfo || null

      if ((userInfo && userInfo.roles.indexOf('ROLE_OPERATIONAL_ANALIST') !== -1) || (userInfo && userInfo.roles.indexOf('ROLE_OPERATIONAL_LEADER') !== -1)) {
        return true
      }
      return false
    },
    isFinancialOperating () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_FINANCIAL_OPERATING') !== -1) {
        return true
      }
      return false
    },
    isLeader () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_LEADER') !== -1) {
        return true
      }
      return false
    },
    isComercialAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_COMERCIAL_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isPortalTester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PORTAL_TESTER') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }
      return false
    },
    isERoyalSeller () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_EROYAL_SELLER') !== -1) {
        return true
      }
      return false
    },
    isEmployee () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      }
      return false
    },
    isPricing () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_PRICING') !== -1) {
        return true
      }
      return false
    },
    representsAlanAsSeller () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_REPRESENTS_ALAN_AS_SELLER') !== -1) {
        return true
      }
      return false
    },
    proposalAdmin () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_PROPOSAL_ADMIN') !== -1) {
        return true
      }
      return false
    },
    followsAccessRequests () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_VIEW_USER_ACCESS_REQUEST') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isValuationManager () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_VALUATION_MANAGER') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
  },
  mounted () {
    this.$nextTick(function () {
      // SIDEBAR ACTIVATE METISMENU
      if (jQuery('.metismenu').length) {
        jQuery('.metismenu').metisMenu()
      }
    })

    this.$nextTick(function () {
      const ps = []
      let customScrolls = document.querySelectorAll('.custom-scroll')
      for (var i = 0; i < customScrolls.length; i++) {
        // eslint-disable-next-line
        ps[ps.length] = new PerfectScrollbar(customScrolls[i])
      }
    })
  }
}
</script>

<style scoped>
body.mini-sidebar .page-sidebar .sidebar-header {
    padding-left: 13px;
    margin-top: -1rem;
    padding-right: 13px;
    margin-bottom: -2rem;
}
  li{
    font-family: 'Pluto Sans';
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  .page-sidebar{
    background-color: #000 ;
  }
  .eroyalless{
    font-weight: 200;
    font-size: 13pt;
  }
  .eroyal{
    font-weight: 900;
    font-size: 15pt;
  }
  .sidebar-menu a {
    white-space: normal;
  }
  .nav-label-mobile{
    color:#a5a4a4 !important;
  }
  .sidebar-points .rc-logo, .sidebar-brand-mini .rc-logo{
    color: #fff !important;
  }
  .logoEroyal {
    padding-left: 5.5vw;
    width: 317px;
    height: 83px;
    max-width: 180%;
  }
</style>
